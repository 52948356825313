.dark > div > div > .rdt_TableHead > .rdt_TableHeadRow {
    background-color: rgb(52, 61, 85);
}

.dark > div > div > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol {
    color: rgb(180, 183, 189);
}

.dark > div > div > .rdt_TableBody > .rdt_TableRow {
    background-color: rgb(40, 48, 70);
    border-color: rgb(59, 66, 83);
    color: rgb(180, 183, 189);
}

.dark {
    background-color: rgb(52, 61, 85) !important;
    color: rgb(180, 183, 189) !important;
}